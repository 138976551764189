import React, {useEffect, useRef} from "react";
import {BotMessage, UserMessage} from "../../../../atoms/Text";
import './_style.scss'

const ChatMessage = ({ messageList }) => {

    const MessageRef = useRef();

    // 스크롤 이벤트
    useEffect(() => {
        MessageRef.current.scrollTo({
            top: 9999999999,
            behavior: "smooth"
        })
    }, [messageList])

    return (
        <div className="messages" ref={MessageRef}>
            {messageList.map((message, index) => {
                if (message.sender === "user") {
                    return (
                        <UserMessage key={`userMessage_${index}`} message={message} />
                    )
                } else if (message.sender === "assistant") {
                    return (
                        <BotMessage key={`BotMessage_${index}`} message={message}/>
                    )
                }
            })}
        </div>
    )
}

export default ChatMessage;