import React, {useEffect, useState} from 'react';
// import FileUploadModal from './FileUploadModal/index.js';
import FileTable from "../../molecules/file/FileListTable";
import {isActiveModal, setModal} from "../../../modules/actions/modalAction";
import {useDispatch, useSelector} from "react-redux";
import FileUploadModal from "../../molecules/file/FileUploadModal";
import axiosInstance from "../../../modules/util/axiosInstance";
import FileStatus from "../../molecules/file/FileListTable/fileStatus";

const FileList = () => {
    const dispatch = useDispatch();
    const modal = useSelector(status => status.isActiveModal);

    const [fileList, setFileList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0)

    const openModal = () => {
        dispatch(setModal({
            title: "파일 업로드",
            size: {
                width: 30,
                height: 60
            },
            component: <FileUploadModal/>
        }))
        dispatch(isActiveModal());
    }

    useEffect(() => {
        getFileList();
    }, [modal, page]);

    // 파일정보 가져오기
    const getFileList = async () => {
        try {
            await axiosInstance.get('/api/file/list', {
                    params: {
                        page: page,
                        size: 10
                    }
                }
            ).then((e) => {
                const responseData = e.data.data;
                setFileList(responseData?.itemList.map(data => {
                    data["parsingStatus"] = <FileStatus id={data.fileName} />
                    return data;
                }));
                setTotalPage(Math.ceil(responseData?.total / 10));
            })
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="FileList">
            <h2>FileList</h2>
            <div className="addFile">
                <i className="ri-upload-2-line" onClick={() => openModal()}/>
            </div>
            <FileTable list={fileList} page={page} setPage={setPage} totalPage={totalPage} />
        </div>
    )
}

export default FileList;
