import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import "./_style.scss";

const AgentCreateModal = ({onClose}) => {
    const [agentName, setAgentName] = useState("");
    const [agentCategory, setAgentCategory] = useState("customer");
    const [systemPrompt, setSystemPrompt] = useState("");
    const [userPrompt, setUserPrompt] = useState("");
    const [description, setDescription] = useState("");

    const [hyde, setHyde] = useState("false");
    const [cot,setCot] = useState("false");
    const [dsp, setDsp] = useState("false");
    const [refine, setRefine] = useState("false");
    const [model, setModel] = useState("gpt-4o");


    const userInfo = useSelector(state => state.userInfo);

    const saveAgent = async () => {// 기본 폼 제출 동작 방지

        try {
            const response = await fetch(`/api/agent/create`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userInfo.accessToken}`,
                    'Accept': '*/*',
                },
                body: JSON.stringify({
                    agentName: agentName,
                    category: agentCategory,
                    systemPrompt: systemPrompt,
                    userPrompt: userPrompt,
                    description: description,
                    hyde: hyde,
                    cot: cot,
                    dsp: dsp,
                    refine: refine,
                    model: model
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Success:', data);

            // 성공 시 알림 표시
            alert("저장되었습니다!");

            // 입력 필드 초기화
            setAgentName("");
            setAgentCategory("");
            setSystemPrompt("");
            setUserPrompt("");
            setDescription("")

        } catch (error) {
            console.error('Error:', error);
        }
    }


    return (
        <div className="agent-select-modal-overlay">
            <form onSubmit={saveAgent} className="agent-select-modal">
                <button onClick={onClose} className="close-button">X</button>

                {/* 에이전트 이름 입력 */}
                <div className="form-group">
                    <label>Agent Name:</label>
                    <input
                        name="AgentName"
                        type="text"
                        value={agentName}
                        onChange={(e) => setAgentName(e.target.value)}
                    />
                </div>

                {/* 에이전트 카테고리 라디오 버튼 */}
                <div className="form-group">
                    <label>Agent Category:</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                value="customer"
                                checked={agentCategory === "customer"}
                                onChange={(e) => setAgentCategory(e.target.value)}
                            />
                            Customer
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="employee"
                                checked={agentCategory === "employee"}
                                onChange={(e) => setAgentCategory(e.target.value)}
                            />
                            Employee
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="data"
                                checked={agentCategory === "data"}
                                onChange={(e) => setAgentCategory(e.target.value)}
                            />
                            Data
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="security"
                                checked={agentCategory === "security"}
                                onChange={(e) => setAgentCategory(e.target.value)}
                            />
                            Security
                        </label>
                    </div>
                </div>

                {/* Prompt 기법 체크박스 */}
                <div className="form-group">
                    <label>Prompt 기법 적용:</label>
                    <div className="checkbox-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={hyde === "true"}
                                onChange={(e) => setHyde(e.target.checked ? "true" : "false")}
                            />
                            Hyde
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={cot === "true"}
                                onChange={(e) => setCot(e.target.checked ? "true" : "false")}
                            />
                            CoT
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={dsp === "true"}
                                onChange={(e) => setDsp(e.target.checked ? "true" : "false")}
                            />
                            DSP
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={refine === "true"}
                                onChange={(e) => setRefine(e.target.checked ? "true" : "false")}
                            />
                            Refine
                        </label>
                    </div>
                </div>

                {/* LLM 모델 라디오 버튼 */}
                <div className="form-group">
                    <label>LLM 모델:</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                value="gpt-4o"
                                checked={model === "gpt-4o"}
                                onChange={(e) => setModel(e.target.value)}
                            />
                            GPT-4o
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="claude"
                                checked={model === "claude"}
                                onChange={(e) => setModel(e.target.value)}
                            />
                            Claude Haiku
                        </label>
                    </div>
                </div>

                {/* System Prompt */}
                <div className="form-group">
                    <label>System Prompt:</label>
                    <textarea
                        className="promptTextArea"
                        name="SystemPrompt"
                        value={systemPrompt}
                        onChange={(e) => setSystemPrompt(e.target.value)}
                    />
                </div>

                {/* User Prompt */}
                <div className="form-group">
                    <label>User Prompt:</label>
                    <textarea
                        className="promptTextArea"
                        name="UserPrompt"
                        value={userPrompt}
                        onChange={(e) => setUserPrompt(e.target.value)}
                    />
                </div>

                {/* Description */}
                <div className="form-group">
                    <label>Description:</label>
                    <input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>


                {/* 저장 버튼 */}
                <div className="submit-box">
                    <button type="submit">SAVE</button>
                </div>
            </form>
        </div>
    );
}

export default AgentCreateModal;
