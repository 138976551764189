import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setUserInfo} from "../modules/actions/setUserInfo";
import axiosInstance from "../modules/util/axiosInstance";

const ProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const [userAccessed, setUserAccessed] = useState(false);
    const userInfo = useSelector(status => status.userInfo);

    const getUserInfo = async () => {
        try {
            await axiosInstance.get('/api/member/me', {
                headers: {
                    'Authorization': `Bearer ${userInfo.accessToken}`,
                    'Accept': '*/*'
                }
            }).then(e => {
                const responseData = e.data.data;
                dispatch(setUserInfo({
                    id: responseData?.loginId,
                    name: responseData?.username,
                    accessToken: localStorage.getItem("lloydk_Sass_accessToken"),
                    refreshToken: localStorage.getItem("lloydk_Sass_refreshToken")
                }))

                setUserAccessed(true);
            })
        } catch (e) {

        }
    }

    // 페이지 이동시마다 token 값 확인
    useEffect(() => {
        getUserInfo();
    }, []);

    return userAccessed ? children : null;
};

export default ProtectedRoute;