import React, {useEffect, useState} from 'react';

const TableCell = (props) => {
    const [displayedChildren, setDisplayedChildren] = useState(props.children);

    useEffect(() => {
        setDisplayedChildren(props.children);
    }, [props.children]);

    return (
        <tr>{displayedChildren}</tr>
    )
}

export default TableCell;