import React, {useRef, useState} from 'react';
import Input from "../../atoms/Input";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../../../modules/actions/setUserInfo";
import axios from "axios";
import {isActiveModal, isActiveModalTrue, setModal} from "../../../modules/actions/modalAction";
import Modal from "../../atoms/Modal";

const Login = () => {
    const role = process.env.REACT_APP_ROLE;
    const responseURL = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const [loginFailMessage, setLoginFailMessage] = useState({
        active: false,
        text: ""
    })

    const id = useRef();
    const password = useRef();

    const checkValue = () => {
        // 아이디 확인
        if (id.current.value === "") {
            id.current.focus();
            setLoginFailMessage({
                active: true,
                text: <i className="ri-information-line"> Please enter your ID.</i>
            })
            return false;
        }

        // password 확인
        else if (password.current.value.length < 8) {
            password.current.focus();
            setLoginFailMessage({
                active: true,
                text: <i className="ri-information-line"> Password Please enter at least 8 digits.</i>
            })
            return false;
        } else {
            return true;
        }
    }

    // 로그인 api
    const onSubmit = async () => {
        setLoginFailMessage({
            active: false,
            text: ""
        })

        if (checkValue()) {
            try {
                await axios.post('/api/auth/login', {
                        loginId: id.current.value,
                        password: password.current.value,
                        sso: ""
                    }, {
                        headers: {
                            'accept': '*/*',
                            'Content-Type': 'application/json'
                        }
                    }
                ).then((e) => {
                    dispatch(setUserInfo({
                        name: "admin",
                        id: id.current.value,
                        accessToken: e.data.accessToken,
                        refreshToken: e.data.refreshToken
                    }));

                    // 로컬 스토리지에 저장
                    localStorage.setItem("lloydk_Sass_accessToken", e.data.accessToken);
                    localStorage.setItem("lloydk_Sass_refreshToken", e.data.refreshToken);

                    window.location.href = "/";
                })
            } catch (e) {
                if (e.status === 403) {
                    setLoginFailMessage({
                        active: true,
                        text: <i className="ri-information-line"> ID or password does not match.</i>
                    })
                } else {
                    setLoginFailMessage({
                        active: true,
                        text: <i className="ri-information-line"> Internal server error.</i>
                    })
                }
            }
        }
    }

    return (
        <div className="login">
            <div className="login_Info">
                <div className="intro_text">
                    LLOYDK SaaS Service
                </div>
            </div>
            <div className="login_div">
                <img src="/images/logo-inverse.png" alt=""/>
                <br/>
                <p>v{process.env.REACT_APP_VERSION}</p>
                <div className="LoginBox">
                    <Input inputRef={id}
                           label={"아이디"}
                           type={"text"}
                           required={true}/>
                    <Input inputRef={password}
                           onKeyup={(e) => (e.keyCode === 13) ? onSubmit() : ""}
                           label={"패스워드"}
                           type={"password"} required={true}/>
                    <div className="forgetPassword">
                        <a>forgot password</a>
                    </div>
                    <div style={{visibility: loginFailMessage.active ? "" : "hidden"}} className="loginFailMessage">
                        {loginFailMessage.text}
                    </div>
                </div>

                <div onClick={() => onSubmit()} className="loginSubmit">
                    로그인
                </div>
                {
                    role === 'user' ? <div
                        onClick={() => window.location.href = `https://sso.hub.dpgtestbed.kr/realms/dpg/protocol/openid-connect/auth?client_id=lloydk&response_type=code&redirect_uri=${responseURL}/dpglogin&scope=openid`}
                        className="loginSubmit">
                        DPG V1
                    </div> : null
                }
                {
                    role === 'user' ? <div
                        onClick={() => window.location.href = `https://sso.dpgtestbed.kr/realms/dpg/protocol/openid-connect/auth?client_id=lloydk&response_type=code&redirect_uri=${responseURL}/dpglogin&scope=openid`}
                        className="loginSubmit">
                        DPG V2
                    </div> : null
                }
            </div>

            <Modal/>
        </div>
    )
}

export default Login;