import React, {useEffect, useState} from "react";
import "./_style.scss";
import DateToFileSize from "./dateToFileSize";
import UserToFileSize from "./userToFileSize";
import {getToken} from "../../../../modules/util/superset";

const HomeChart = () => {
    const [supersetGuestToken, setSupersetGuestToken] = useState(null);

    useEffect(() => {
        getGuestToken()
    }, []);

    const getGuestToken = async () => {
        setSupersetGuestToken(await getToken());
    }

    return (
        <DateToFileSize guestToken={supersetGuestToken}/>
    )
}

export default HomeChart;