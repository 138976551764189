import React, {useEffect, useRef, useState} from 'react';
import Input from "../../../atoms/Input";
import './_style.scss'
import {useDispatch} from "react-redux";
import {isActiveModal} from "../../../../modules/actions/modalAction";
import axiosInstance from "../../../../modules/util/axiosInstance";

const UserCreateModal = (props) => {
    const dispatch = useDispatch();

    const [matchPassword, setMatchPassword] = useState(true);
    const [activeMatchPassword, setActiveMatchPassword] = useState(false)

    const {id, name, role} = props.User;
    const refId = useRef();
    const refName = useRef();
    const refPassword = useRef();
    const refPwCheck = useRef();
    const refRole = useRef();

    useEffect(() => {
        refId.current.value = id ? id : "";
        refName.current.value = name ? name : "";
        refRole.current.value = role ? role : "";
    }, [dispatch, props]);

    const roleOptions = [
        {value: 'admin', label: 'admin', icon: <i className="ri-admin-fill"/>},
        {value: 'user', label: 'user', icon: <i className="ri-user-2-fill"/>}
    ];

    const CheckPassword = () => {
        setActiveMatchPassword(true);
        if (refPassword.current.value === refPwCheck.current.value) {
            setMatchPassword(true)
        } else {
            setMatchPassword(false)
        }
    }

    const onSubmit = async () => {
        const apiPath = props.update ? '/api/member/update' : '/api/member/create'
        const params = {
            loginId: refId.current.value,
            username: refName.current.value,
            password: refPassword.current.value,
            role: refRole.current.value
        }

        await axiosInstance.post(apiPath, params);
        dispatch(isActiveModal());
    }

    return (
        <div className="userCreateModal">
            <div className="user-input-box">
                <div className="user_id_name_input">
                    <Input inputRef={refId} label={"아이디"} type={"read"} required={true} readOnly={true}/>
                    <Input inputRef={refName} label={"사용자 이름"} type={"text"} required={true}/>
                </div>
                <Input inputRef={refPassword} label={"패스워드"} type={"password"} required={true}/>
                <Input onChange={CheckPassword} inputRef={refPwCheck} label={"패스워드 확인"} type={"password"} required={true}/>
                {
                    matchPassword ?
                        <div style={{visibility: activeMatchPassword ? "" : "hidden"}} className="match_password"> * password가 일치합니다.</div> :
                        <div style={{visibility: activeMatchPassword ? "" : "hidden"}} className="notMatch_password"> * password가 일치하지 않습니다.</div>
                }

                <div className="role-selection">
                    <div className="role-title">권한</div>
                    <div className="role-option" ref={refRole}>
                        {roleOptions.map((option, idx) => (
                            <div key={"select_role_" + idx}>
                                <input
                                    onClick={() => refRole.current.value = option.value}
                                    type="radio"
                                    id={option.value}
                                    name="role"
                                    value={option.value}
                                />
                                <label htmlFor={option.value}>
                                    {option.icon}
                                    {option.label}
                                </label>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

            <div className="Modal_action_box">
                <button onClick={onSubmit}>저장</button>
                <button onClick={() => dispatch(isActiveModal())}>취소</button>
            </div>
        </div>
    )
}

export default UserCreateModal;
