import { embedDashboard } from "@superset-ui/embedded-sdk";
import axios from "axios";

const supersetUrl = 'https://do-superset.lloydk.co.kr'
const supersetApiUrl = supersetUrl + '/api/v1/security'

export async function getToken() {
    // This uses admin creds to fetch the token
    const login_body = {
        "password": "admin",
        "username": "admin",
        "provider": "db",
        "refresh": true
    };
    const login_headers = {
        "headers": {
            "Content-Type": "application/json"
        }
    }

    const { data } = await axios.post(supersetApiUrl + '/login', login_body, login_headers)
    const access_token = data['access_token']

    // Calling guest token
    const guest_token_body = JSON.stringify({
        "resources": [
            {
                "type": "dashboard",
                "id": "13adc084-6ccb-4e02-8d1f-6a0be614609c",
            }
        ],
        "rls": [],
        "user": {
            "username": "lloydk",
            "first_name": "lloydk",
            "last_name": "lloydk",
        }
    });

    const guest_token_headers = {
        "headers": {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + access_token
        }
    }


    // Calling guest token endpoint to get the guest_token
    const guest_token = await axios.post(supersetApiUrl + '/guest_token/', guest_token_body, guest_token_headers);

    return guest_token.data['token'];
}