import React, {useRef, useState} from 'react';
import ChatSetting from "./ChatSetting";
import './_style.scss'
import {useSelector} from "react-redux";

const ChatBottom = ({sendMessage}) => {
    const userText = useRef();
    const [settingModal, setSettingModal] = useState(false);
    const agentId = useSelector(state => state.agentId);

    const sendText = () => {
        if(agentId !== ""){
            sendMessage(userText.current.value);
            userText.current.value = "";
        }else{
            alert("에이전트를 설정해주세요!")
        }
    }

    return (
        <div className="chat-input" >
            <i className="ri-settings-2-fill settingsModal" onClick={() => setSettingModal(!settingModal)}/>
            <input ref={userText} type="text"
                   onKeyUp={(e) => (e.keyCode === 13) ? sendText() : ""}
                   placeholder="Type a message..."/>
            <button className="sendBtn" onClick={() => sendText()}>Send</button>
            <ChatSetting settingModal={settingModal} setSettingModal={setSettingModal} />
        </div>
    )
}

export default ChatBottom;