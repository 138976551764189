import React, {useEffect, useState} from 'react';
import axiosInstance from "../../../../modules/util/axiosInstance";

const FileStatus = (props) => {
    const [fileStatus, setFileStatus] = useState();

    useEffect(() => {
        const getFileStatus = async () => {
            try {
                const response = await axiosInstance.get('/api/file/status?fileName=' + props.id);
                const responseData = response.data.data;
                setFileStatus(responseData);

                if(responseData?.parsingStatus === "Finished" && responseData?.percent === 100) {
                    clearInterval(intervalId);
                }
            } catch (e) {
                console.log(e);
            }
        };

        getFileStatus();

        const intervalId = setInterval(getFileStatus, 5000);

        return () => clearInterval(intervalId);
    }, [props.id]);

    const getIcons = (status) => {
        if (status?.parsingStatus === "Error") {
            return <i className="ri-error-warning-line" style={{color: "red", fontWeight: "bold", fontSize: "24px"}}/>
        } else if (status?.parsingStatus === "SAVED") {
            return "대기중"
        } else if(status?.parsingStatus === "Finished" && fileStatus?.percent === 100) {
            return <i className="ri-check-fill" style={{color: "green", fontWeight: "bold", fontSize: "24px"}}/>
        }else {
            switch (Math.floor((fileStatus?.percent / 12.5))) {
                case 0 :
                    return <i className="ri-progress-1-line" style={{color: "black", fontSize: "24px"}}/>
                case 1 :
                    return <i className="ri-progress-1-line" style={{color: "black", fontSize: "24px"}}></i>
                case 2 :
                    return <i className="ri-progress-2-line" style={{color: "black", fontSize: "24px"}}></i>
                case 3 :
                    return <i className="ri-progress-3-line" style={{color: "black", fontSize: "24px"}}></i>
                case 4 :
                    return <i className="ri-progress-4-line" style={{color: "black", fontSize: "24px"}}></i>
                case 5 :
                    return <i className="ri-progress-5-line" style={{color: "black", fontSize: "24px"}}></i>
                case 6 :
                    return <i className="ri-progress-6-line" style={{color: "black", fontSize: "24px"}}></i>
                case 7 :
                    return <i className="ri-progress-7-line" style={{color: "black", fontSize: "24px"}}></i>
                case 8 :
                    return <i className="ri-progress-8-line" style={{color: "black", fontSize: "24px"}}></i>
                default :
                    return "대기중"

            }

        }
    }

    return (
        <>
            {getIcons(fileStatus)}
        </>
    )
}

export default FileStatus;