import React, {useEffect, useState} from "react";
import moment from 'moment';
import Table from "../../../atoms/Table";
import {useSelector} from "react-redux";
import {formatFileSize} from "../../../../modules/util/fileSize";
import axiosInstance from "../../../../modules/util/axiosInstance";
import FileStatus from "./fileStatus";
import './_style.scss';

const FileTable = ({list, page, setPage, totalPage}) => {
    const modal = useSelector(status => status.isActiveModal);

    const [cellList, setCellList] = useState([]);
    const [isActiveActionBtn, setIsActiveActionBtn] = useState({
        active: false,
        idx: 0
    })

    useEffect(() => {
        setCellList(list.map((data, idx) => {
            return (
                <>
                    <td>{idx + 1}</td>
                    <td title={data.originalFileName}>{data.originalFileName}</td>
                    <td>{formatFileSize(data.fileSize)}</td>
                    <td>{getTypeIcon(data.fileType.split("/")[1])}</td>
                    <td>{data.parsingStatus}</td>
                    <td>{moment(data.createdDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                    {/* action Btn tooltip */}
                    <td
                        style={{color: (isActiveToolBox(idx) && isActiveActionBtn.active) ? "#175fc7" : ""}}
                        tabIndex={idx}
                        onBlur={() => setIsActiveActionBtn({active: false, idx: -1})}
                    >
                        <i className="ri-more-2-fill"
                           onClick={() => setIsActiveActionBtn({active: !isActiveActionBtn.active, idx: idx})}/>
                        <div className="actionTooltip"
                             style={{visibility: (isActiveToolBox(idx) && isActiveActionBtn.active) ? "" : "hidden"}}>
                            <div className="actionBtn">
                                <i className="ri-download-line"
                                   onClick={() => downloadFile(data.fileName)}> download</i>
                            </div>
                            <div className="actionBtn" onClick={() => deleteFile(data.fileName)}>
                                <i className="ri-delete-bin-6-line"> delete</i>
                            </div>
                        </div>
                    </td>
                </>
            )
        }));
    }, [list, isActiveActionBtn]);

    // file 다운로드
    const downloadFile = (fileName) => {
        const downloadUrl = `https://do-api.lloydk.co.kr/api/file/download/${fileName}`;
        window.open(downloadUrl, '_blank');
    }

    // 파일 삭제
    const deleteFile = async (fileName) => {
        if (window.confirm("파일을 삭제 하시겠습니까?") === true) {
            try {
                await axiosInstance.delete(`/api/file/${fileName}`);
                setIsActiveActionBtn({active: false, idx: undefined});
            } catch (error) {
                console.error('Error in delete:', error);
            }
        }
    };

    const getTypeIcon = (type) => {
        return {
            jpeg: <i className="ri-image-fill"/>,
            png: <i className="ri-image-fill"/>,
            pdf: <i className="ri-file-pdf-2-fill"/>,
            msword: <i className="ri-file-word-line"/>,
            excel: <i className="ri-file-excel-line"></i>
        }[type.split('/')[type.split('/').length - 1]]
    }

    const isActiveToolBox = (idx) => {
        if (idx === isActiveActionBtn.idx) {
            return true
        } else {
            return false
        }
    }

    const headerData = ["id", "파일명", "파일크기", "확장자", "상태", "업로드 날짜", "action"];

    return (
        <div className="FileListTable">
            <Table headerData={headerData} cellData={cellList}/>

            <div className="table_page">
                {
                    [...Array(totalPage)].map((data, idx) => {
                        return <button style={(idx === page) ? {backgroundColor: "#175fC7", color: "white"} : {}}
                                       key={"tablePageBtn_" + idx} onClick={() => setPage(idx)}>{idx + 1}</button>
                    })
                }
            </div>
        </div>
    )
}
export default FileTable;