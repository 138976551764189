import Header from "./components/organisms/Header";
// import Footer from "./components/organisms/Footer";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from 'react-router-dom';

import store from './modules/store/index';
import AppRouter from "./routers";

function App() {
    return (
        <Provider store={store}>
            <Router>
            <div className="root">
                <Header/>
                <div className="middle">
                    {/* Router -> pages */}
                    <AppRouter/>
                </div>

                {/* 사용 여부 미정 */}
                {/*<Footer/>*/}
            </div>
            </Router>
        </Provider>
    );
}

export default App;
