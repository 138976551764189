import React, {useEffect, useState} from "react";
import ChatList from "../../molecules/chat/ChatList";
import ChatContent from "../../molecules/chat/ChatContent";
import {useSelector} from "react-redux";
import styled from "styled-components";
import axiosInstance from "../../../modules/util/axiosInstance";
import AgentSelectModal from "../../molecules/chat/ChatList/AgentSelectModal/index.js";

const
    Chat = () => {
    const isActiveSideMenu = useSelector(state => state.isActiveSideMenu);
    const [chatList, setChatList] = useState([]);
    const [messageList, setMessageList] = useState([]);
    const chatToken = useSelector(status => status.chatToken);
    const agentId = useSelector(state => state.agentId);

    const [isAgentModalVisible, setAgentModalVisible] = useState(false);


    const handleCloseModal = () => {
        setAgentModalVisible(false);
    };


    const getConversationList = async () => {
        try {
            const response = await axiosInstance.get("/api/chat/list");
            if (response.data.msg === 'success') {
                setChatList(response.data.data.itemList);
            }
        } catch (error) {
            console.error('Error : ' + error);
        }
    }

    const getMessageList = async () => {
        try {
            const response = await axiosInstance.get("/api/chat/" + chatToken + "/list", {
                params: {
                    page: 0,
                    size: 100000
                }
            });
            if (response.data.msg === 'success') {
                const responseData = response.data.data;
                setMessageList(responseData?.itemList);
            }
        } catch (error) {
            console.error('Error : ' + error);
        }
    }


    useEffect(() => {
        getConversationList();
        getMessageList();
    }, []);

    // token 변경시 2초후 실행
    useEffect(() => {

        (chatToken === "") ? setMessageList([]) : getMessageList();

        const setInterval = setTimeout(() => {
            getConversationList();
        }, 2000);

        return () => clearTimeout(setInterval);

    }, [chatToken]);

    useEffect(() => {
        if (agentId === "") {
            setAgentModalVisible(true); // agentId가 없으면 모달 표시
        } else {
            setAgentModalVisible(false); // agentId가 설정되면 모달 숨김
        }
    }, [agentId]);

    return (
        <ChatContainer className="chat-container" activemenu={isActiveSideMenu.toString()}>
            <div className="chat-list">
                <ChatList list={chatList}/>
            </div>
            <ChatContent list={messageList} setList={setMessageList}/>
            {isAgentModalVisible && (
                <AgentSelectModal onClose={handleCloseModal} />
            )}
        </ChatContainer>
    )
}

export default Chat;

const ChatContainer = styled.div`
    width: ${props => props.activemenu === "true" ? 'calc(100% - 170px)' : '100%' };
    
    @media (max-width: 500px) {
        width: 100%
    }
`;

