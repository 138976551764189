import React, { useEffect,useState } from "react";
import './_style.scss';
import Table from "../../../atoms/Table";
import { setAgentId } from "../../../../modules/actions/setAgentId";
import {useDispatch, useSelector} from "react-redux";
import AgentPromptModal from "../AgentPromptModal";
import axiosInstance from "../../../../modules/util/axiosInstance";
import {setChatSetting} from "../../../../modules/actions/setChatSetting";

const AgentListTable = ({ agentList, page, setPage, totalPage, usingSelect }) => {
    const dispatch = useDispatch();
    const [selectedAgent, setSelectedAgent] = useState(null); // 선택된 에이전트를 저장하는 로컬 상태
    const [promptModal, setPromptModal] = useState(false);
    const [newAgentList,setNewAgentList] = useState(agentList);
    const [datainfo, setDatainfo] = useState(null);
    const chatSetting = useSelector(state => state.chatSetting);

    useEffect(() => {
        setNewAgentList(agentList);
    }, [agentList]);

    const setAgentInfo = async (agentId) => {
        try {
            const response = await axiosInstance.get(`/api/agent/agentInfo/${agentId}`);
            if (response.data.msg === 'success') {
                console.log("agentInfo", response.data.data.cot);
                dispatch(setChatSetting({
                    ...chatSetting, agentName: response.data.data.agentName,dsp: response.data.data.dsp, cot: response.data.data.cot,
                    refine: response.data.data.refine, hyde: response.data.data.hyde, model: response.data.data.model
                }))
            }
        } catch (error) {
            console.error('Error : ' + error);
        }
    }

    // 에이전트 선택 버튼 클릭 시 ID를 설정하고 선택된 에이전트 상태 업데이트
    const handleSelectClick = (agent) => {
        dispatch(setAgentId(agent.id)); // 선택된 에이전트 ID를 설정
        setSelectedAgent(agent); // 선택된 에이전트 상태 업데이트
        setAgentInfo(agent.id);
    };

    const handleDetailClick = (agent) => {
        // 상세보기 버튼 클릭 시 수행할 작업을 여기에 추가
        console.log(`Detail view for agent: ${agent.agentName}`);
        setDatainfo(agent);
        setPromptModal(true);
    };

    const handleDetailClose = () => {
        setPromptModal(false);
    };

    const handleDeleteClick = async (agent) => {
        if(window.confirm("해당 에이전트를 삭제하시겠습니까?") === true){
            try{
                await axiosInstance.delete(`/api/agent/${agent.id}`);
                alert("삭제 완료!")
                setNewAgentList(newAgentList.filter(item => item.id !== agent.id));
            }catch(error){
                console.log("AgentDelete Error",error);
            }
            console.log(`Delete agent: ${agent.agentName}`);
        }
    };

    const headerData = usingSelect ? ["", "agentName", "desription", "select"] : ["order", "agentName", "category", "detail", "delete"];
    const cellData = newAgentList.map((data, idx) => (
        <>
            <td style={{ width: "5%" }}>{idx + 1}</td>
            <td>{data.agentName}</td>
            {usingSelect ? (
                <>
                    <td>{data.description}</td>
                <td>
                    <button className="agent-select-button" onClick={() => handleSelectClick(data)}>
                        선택
                    </button>
                </td>
                </>
            ) : (
                <>
                    <td>{data.category}</td>
                    <td>
                        <button className="agent-detail-button" onClick={() => handleDetailClick(data)}>
                            상세보기
                        </button>
                    </td>
                    <td>
                        <button className="agent-delete-button" onClick={() => handleDeleteClick(data)}>
                            삭제
                        </button>
                    </td>
                </>
            )}
        </>
    ));

    return (
        <div className="userListTable">
            {selectedAgent && (
                <div className="selected-agent-info">
                    <h3>Selected Agent:</h3>
                    <p>{selectedAgent.agentName}</p>
                </div>
            )}

            <Table headerData={headerData} cellData={cellData} />

            <div className="table_page">
                {
                    [...Array(Math.ceil(totalPage / 5))].map((_, idx) => (
                        <button
                            style={(idx === page) ? { backgroundColor: "#175fC7", color: "white" } : {}}
                            key={"tablePageBtn_" + idx}
                            onClick={() => setPage(idx)}
                        >
                            {idx + 1}
                        </button>
                    ))
                }
            </div>
            <div>
                {promptModal && (<AgentPromptModal onClose={handleDetailClose} data={datainfo} />)}
            </div>
        </div>
    );
};

export default AgentListTable;
