import React, {useEffect, useRef} from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";

const DateToFileSize = ({guestToken}) => {
    const dashboardRef = useRef();

    useEffect(() => {
        if(guestToken) {
            embedSupersetDashboard();
        }
    }, [guestToken]);

    const embedSupersetDashboard = async () => {
        await embedDashboard({
            id: "13adc084-6ccb-4e02-8d1f-6a0be614609c",
            supersetDomain: 'https://do-superset.lloydk.co.kr',
            mountPoint: dashboardRef.current,
            fetchGuestToken: () => guestToken,
            dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: true,
                hideTab: true,
                filters: {
                    visible: false,
                    expanded: false,
                }
            },
            dashboardLayout: "fullscreen",
        });
    }

    return (
        <div className="Chart" ref={dashboardRef}/>
    )
}

export default DateToFileSize;