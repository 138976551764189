import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isActiveModal, setModal} from "../../../modules/actions/modalAction";

const Modal = () => {
    const activeModal = useSelector(status => status.isActiveModal);
    const modalComponent = useSelector(status => status.modalComponent);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(setModal({
            title: "",
            size: {},
            component: <></>
        }))
        dispatch(isActiveModal());
    }

    //사이즈에 따른 Body 설정
    const setModalBodySize = (size) => {
        //500 보다 작은때 사이즈 고정
        if(window.innerWidth <= 500) {
            size = {width: 80, height: 70};
        }

        if (size) {
            return {
                width: size.width + "%",
                height: size.height + "%",
            }
        }
    }

    return (
        <div style={{display: activeModal ? "" : "none"}} className="modal">
            <div className="modalBody" style={setModalBodySize(modalComponent.size)}>
                <div className="closeBtn">
                    <i className="ri-close-line" onClick={() => closeModal()}/>
                </div>
                <div className="modalTitle">
                    {modalComponent.title}
                </div>
                {modalComponent.component}
            </div>
        </div>
    )
}

export default Modal;