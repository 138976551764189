import React from 'react';
import TableCell from "./tabelCell";
import TableHeaderCell from "./tableHeaderCell";

const Table = (props) => {

    return (
        <table className="table">
            <thead>
            <tr>
                {
                    props.headerData.map((data, idx) => {
                        return <TableHeaderCell key={"header_" + idx}>{data}</TableHeaderCell>
                    })
                }
            </tr>
            </thead>
            <tbody className="tableBody">
            {
                props.cellData.map((data, idx) => {
                    return <TableCell key={"cell" + idx}>{data}</TableCell>
                })
            }
            </tbody>
        </table>
    )
}

export default Table