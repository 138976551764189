import React, {useRef, useState} from 'react';
import './_style.scss';
import {useDispatch} from "react-redux";
import {isActiveModal} from "../../../../modules/actions/modalAction";
import {formatFileSize} from "../../../../modules/util/fileSize";
import axiosInstance from "../../../../modules/util/axiosInstance";

const MAX_FILE_SIZE = 500 * 1024 * 1024;

const FileUploadModal = () => {
    const dispatch = useDispatch();
    const files = useRef();
    const [fileList, setFileList] = useState([]);

    const checkFile = () => {
        const file = files.current.files[0];
        if (file && file.size >= MAX_FILE_SIZE) {
            alert('파일 크기가 500MB를 초과합니다. 다른 파일을 선택해주세요.');
        } else {
            setFileList([...fileList, file]);
        }
    }


    const getTypeIcon = (type) => {
        return {
            jpeg: <i className="ri-image-fill"/>,
            png: <i className="ri-image-fill"/>,
            pdf: <i className="ri-file-pdf-2-fill"/>,
            msword: <i className="ri-file-word-line"/>,
            excel: < i class="ri-file-excel-line"></i>
        }[type.split('/')[type.split('/').length - 1]]
    }

    const removeFile = (idx) => {
        setFileList((list) => list.filter((_, index) => index !== idx))
    }

    const uploadFile = async (e) => {
        // upload 버튼 비활성화
        e.target.disabled = true;

        if (fileList.length === 0) {
            alert("파일을 올려주세요")
        } else {
            const formData = new FormData();

            fileList.map(data => {
                formData.append('multipartFiles', data);
            })

            try {
                await axiosInstance.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'accept': '*/*'
                    }
                }).then((e) => {
                    alert('파일이 성공적으로 업로드되었습니다.');
                    dispatch(isActiveModal());
                });
            } catch (error) {
                alert(`파일 업로드 중 오류가 발생했습니다: ${error.message}`);
            }
        }

        e.target.disabled = false;
    }

    return (
        <div className="fileUploadModal">
            <div className="fileDragAndDropBox">
                <label htmlFor="file">
                    <div className="btn-upload">
                        <i className="ri-upload-cloud-2-line"/>
                        <br/>
                        파일 업로드하기
                    </div>
                </label>
                <input onChange={checkFile} ref={files} type="file" name="file" id="file"/>
            </div>
            <div className="fileList">
                {
                    fileList.map((data, idx) => {
                        return (
                            <div key={"uploadFileList_" + idx} className="uploadFileInfo">
                                {getTypeIcon(data.type) ? getTypeIcon(data.type) : <i className="ri-file-line"/>}
                                <div className="fileTitle">
                                    {data.name}
                                </div>
                                <div className="fileSize">
                                    {formatFileSize(data.size)}
                                </div>
                                <div className="removeFile">
                                    <i className="ri-close-circle-line" onClick={() => removeFile(idx)}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="Modal_action_box">
                <button onClick={(e) => uploadFile(e)}>업로드</button>
                <button onClick={() => dispatch(isActiveModal())}>취소</button>
            </div>
        </div>
    )
}

export default FileUploadModal;